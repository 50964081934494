export function LabCartIcon({ empty, ...props }: React.SVGProps<SVGSVGElement> & { empty?: boolean }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={14} height={14} fill="none" {...props}>
      <g clipPath="url(#a)">
        <g stroke="#000" clipPath="url(#b)">
          <path strokeWidth={1.16} d="M.58 4.58h12.84v7.84H.58z" />
          <path strokeWidth={1.167} d="M5 4.3V2.954C5 1.877 5.667.796 7 .8c1.333.004 2 1.077 2 2.154V4.3" />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h14v14H0z" />
        </clipPath>
        <clipPath id="b">
          <path fill="#fff" d="M0 0h14v14H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
