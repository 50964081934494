export function isObject(value: unknown): value is Record<string, unknown> {
  // other primitives
  if (typeof value !== 'object') return false;
  // null
  if (value === null) return false;
  // array
  if (Array.isArray(value)) return false;
  // functions
  if (value instanceof Function) return false;
  // regex
  if (value instanceof RegExp) return false;
  // Map
  if (value instanceof Map) return false;
  // Set
  if (value instanceof Set) return false;
  // WeakMap
  if (value instanceof WeakMap) return false;
  // WeakSet
  if (value instanceof WeakSet) return false;
  // Date
  if (value instanceof Date) return false;
  // object
  return true;
}

export function isClientSide() {
  return typeof window !== 'undefined';
}

export function isString(value: unknown): value is string {
  return typeof value === 'string';
}

// no se puede usar hover:hover solo porque los samsung detectan incorrectamente el input. https://issues.chromium.org/issues/41445959
export function canUserHover() {
  if (typeof window === 'undefined') return false;
  return window.matchMedia('(pointer: fine) and (hover: hover)').matches;
}
